import React, { useLayoutEffect, useState } from 'react';

import image1 from '../../assets/images/endo-hero-image-1.png';
import image1webp from '../../assets/images/endo-hero-image-1.webp';
import image1_s from '../../assets/images/endo-hero-image-1-s.png';
import image1webp_s from '../../assets/images/endo-hero-image-1-s.webp';

import image2 from '../../assets/images/endo-hero-image-2.jpg';
import image2webp from '../../assets/images/endo-hero-image-2.webp';

import image2_s from '../../assets/images/endo-hero-image-2-s.jpg';
import image2webp_s from '../../assets/images/endo-hero-image-2-s.webp';
import image2_s2x from '../../assets/images/endo-hero-image-2-s@2x.jpg';
import image2webp_s2x from '../../assets/images/endo-hero-image-2-s@2x.webp';

function EndoHero() {
  const [isEdge, setIsEdge] = useState(false);

  useLayoutEffect(() => {
    if (window.navigator.userAgent.indexOf('Edg') > -1) {
      setIsEdge(true);
    }
  }, []);

  return (
    <section className="relative endo-hero">
      <div className={`container mx-auto ${isEdge ? 'endo-hero-is-edge' : ''}`}>
        <div className="text-white-100 flex flex-col md:flex-row justify-between items-center">
          <h1 className="text-center">
            Living with endometriosis pain will never be easy.
          </h1>

          <span className="text-center">
            With Myfembree, I could have more days like this.
          </span>



          <div className="absolute left-0 top-0 flex flex-col md:flex-row endo-hero-image">
            <picture className="">
              <source
                srcSet={image1webp_s}
                media="(max-width: 672px)"
                type="image/webp"
              />

              <source srcSet={image1_s} media="(max-width: 672px)" />

              <source
                srcSet={image1webp}
                type="image/webp"
                media="(min-width: 672px)"
              />

              <source srcSet={image1} media="(min-width: 672px)" />

              <img
                className="w-100 img-fluid"
                src={image1}
                alt="Woman experiencing endometriosis pain. Not an actual patient."
                width={720}
                height={626}
              />
            </picture>

            <picture className="relative">
              <source
                srcSet={`${image2webp_s}, ${image2webp_s2x} 2x`}
                media="(max-width: 896px)"
                type="image/webp"
              />

              <source
                srcSet={`${image2_s}, ${image2_s2x} 2x`}
                media="(max-width: 896px)"
              />

              <source
                srcSet={image2webp}
                type="image/webp"
                media="(min-width: 896px)"
              />

              <source srcSet={image2} media="(min-width: 896px)" />

              <img
                className="w-100 img-fluid"
                src={image2webp_s}
                alt="Woman living with endometriosis pain and taking MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) and socializing with loved ones. Not an actual patient."
                width={720}
                height={626}
                fetchpriority="high"
              />
            </picture>
          </div>
        </div>

        {/* <small className="text-center md:text-right block text-white-100">
          Myfembree should not be taken for more than 24 months
        </small> */}
      </div>
    </section>
  );
}

export default EndoHero;
