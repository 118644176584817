import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

import myfembreeIcon from '../../assets/images/myfembree-icon.svg';
import doctorIcon from '../../assets/images/doctor-icon.svg';
import dollarIcon from '../../assets/images/dollar-icon.svg';

import useIsEdge from '../../util/use-is-edge';

const cardList = [
  {
    icon: myfembreeIcon,
    iconW: 68,
    iconH: 76,
    title: 'What is Myfembree?',
    text: (
      <>
        Myfembree is a <br className="" /> once-daily pill proven to relieve
        moderate to severe endometriosis pain in premenopausal women.
      </>
    ),
    btn: <>SEE PROVEN RESULTS</>,
    link: '/endometriosis/why-myfembree/',
    terms: false,
    alt: ''
  },
  {
    icon: doctorIcon,
    iconW: 68,
    iconH: 82,
    title: 'Ready to ask about Myfembree?',
    text: 'If your doctor already knows about your endo pain, sharing how the pain impacts daily activities could help you get the relief you’re looking for.',
    btn: (
      <>
        START THE <br className="md:hidden" /> CONVERSATION
      </>
    ),
    link: '/endometriosis/at-the-doctor/',
    terms: false,
    alt: ''
  },
  {
    icon: dollarIcon,
    iconW: 44,
    iconH: 83,
    title: 'How can you save on Myfembree?',
    text: (
      <>
        If you have commercial insurance, you could pay as little as $5 for your
        Myfembree prescription (up to $5,000 per{' '}
        <br className="hidden md:block" /> calendar year).*
      </>
    ),
    btn: (
      <>
        EXPLORE MYFEMBREE <br className="md:hidden" /> COST SAVINGS
      </>
    ),
    link: '/endometriosis/cost-and-support/',
    terms: true,
    alt: ''
  }
];

function Cards() {
  const isEdge = useIsEdge();

  return (
    <section>
      <div className={`container mx-auto ${isEdge ? 'is-edge-container' : ''}`}>
        <div className="endo-note w-[100%] text-center md:text-end mx-auto flex content-center">
          <small className="block text-white-100">
            Not actual patients throughout
          </small>
        </div>
        <ul className="flex flex-wrap lg:flex-nowrap cards-list mx-auto">
          {cardList.map((card, index) => {
            return (
              <li className="flex flex-col items-center" key={index}>
                <img
                  src={card.icon}
                  alt={card.alt}
                  width={card.iconW}
                  height={card.iconH}
                />

                <h2 className="font-mulish font-bold text-white-100 text-center">
                  {card.title}
                </h2>

                <p className="text-white-100 text-center relative">
                  {card.text}
                </p>

                {index === 0 && (
                  <p className="text-white-100 should-not text-center">
                    Myfembree should not be taken for more than 24 months.
                  </p>
                )}

                <Link className="font-mulish text-center" to={card.link}>
                  {card.btn}
                </Link>

                {card.terms && (
                  <Link
                    className="terms"
                    to="/cost-and-support/#terms-and-conditions"
                  >
                    TERMS AND CONDITIONS
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default Cards;
