import React from 'react';

import endoReliefBg from '../../assets/images/endo-relief-bg.jpg';
import endoReliefBgWebp from '../../assets/images/endo-relief-bg.webp';
import endoReliefBg_s from '../../assets/images/endo-relief-bg-s.jpg';
import endoReliefBgWebp_s from '../../assets/images/endo-relief-bg-s.webp';
import endoReliefBg_s2x from '../../assets/images/endo-relief-bg-s@2x.jpg';
import endoReliefBgWebp_s2x from '../../assets/images/endo-relief-bg-s@2x.webp';

function EndoRelief() {
  return (
    <section className="endo-relief relative">
      <div className="container mx-auto container-endo">
        <h2 className="text-white-100 text-center">
          <span className="block text-center mx-auto">
            I didn’t think endo pain relief would be possible.
          </span>
          <span className="block">I’m glad I was wrong.</span>
        </h2>
      </div>

      <picture className="endo-relief-img absolute top-0">
        <source
          srcSet={`${endoReliefBgWebp_s}, ${endoReliefBgWebp_s2x} 2x`}
          media="(max-width: 896px)"
          type="image/webp"
        />

        <source
          srcSet={`${endoReliefBg_s}, ${endoReliefBg_s2x} 2x`}
          media="(max-width: 896px)"
        />

        <source
          srcSet={endoReliefBgWebp}
          type="image/webp"
          media="(min-width: 896px)"
        />

        <source srcSet={endoReliefBg} media="(min-width: 896px)" />

        <img
          className="w-100 img-fluid"
          src={endoReliefBgWebp_s}
          alt="Woman experiencing relief from endometriosis pain by using MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) and playing fetch with her dog. Not an actual patient."
          width={1440}
          height={587}
        />
      </picture>
    </section>
  );
}

export default EndoRelief;
